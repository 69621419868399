"use strict";

function initAcc(elem) {
  var option = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  document.querySelectorAll('.accordion-title').forEach(function (item) {
    item.addEventListener('click', function (e) {
      e.preventDefault();

      if (!e.target.parentElement.classList.contains('active')) {
        if (option == true) {
          var elementList = document.querySelectorAll(elem + ' .accordion-wrapper');
          Array.prototype.forEach.call(elementList, function (e) {
            e.classList.remove('active');
          });
        }

        e.target.parentElement.classList.add('active');
      } else {
        e.target.parentElement.classList.remove('active');
      }
    });
  });

  if (window.location.hash) {
    document.querySelector(window.location.hash).classList.add('active');
  }
}

document.querySelector('.hamburger').addEventListener('click', function (e) {
  e.preventDefault();
  document.querySelector('.navigation').classList.add('open');
  document.body.classList.add('overflow-hidden');
});
document.querySelector('.navigation_hamburger').addEventListener('click', function (e) {
  e.preventDefault();
  document.querySelector('.navigation').classList.remove('open');
  document.body.classList.remove('overflow-hidden');
});
document.querySelectorAll('.barcode button').forEach(function (element) {
  element.addEventListener('click', function (e) {
    try {
      var parent = element.closest('.product-row');

      if (!parent.querySelector('.product--feature').classList.contains('active')) {
        document.querySelectorAll('.product--feature').forEach(function (item) {
          item.classList.remove('active');
        });
        parent.querySelector('.product--feature').classList.add('active');
      } else {
        parent.querySelector('.product--feature').classList.remove('active');
      }
    } catch (error) {
      console.log(error);
    }
  });
});
"use strict";

//scroll effect
var observedElements = document.querySelectorAll('.inview-element');

var inViewCallback = function inViewCallback(entries) {
  entries.forEach(function (entry) {
    if (entry.isIntersecting) {
      entry.target.classList.add('inview');
    }
  });
};

var observer = new IntersectionObserver(inViewCallback, {
  threshold: 0.6
});
observedElements.forEach(function (element) {
  var dataDelay = element.getAttribute('data-delay') || 200;
  element.style.transitionDelay = dataDelay + 'ms';
  observer.observe(element);
});